import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map';
import { NotificationService } from './notification.service';
import { YOUTUBE_API_KEY } from '../constants';

@Injectable()
export class YoutubeApiService {
  base_url = 'https://www.googleapis.com/youtube/v3/';
  list_id = 'PLJKqAXVXEr77KX8t7PDqYiY7ssnH5bgal';
  max_results = 50;

  public nextToken: string;
  public lastQuery: string;

  constructor(
    private http: Http,
    private notificationService: NotificationService
  ) { }

  searchVideos(): Promise<any> {
    const url = `${this.base_url}playlistItems?part=snippet&playlistId=${this.list_id}&maxResults=${this.max_results}&type=video&part=snippet,id&key=${YOUTUBE_API_KEY}&videoEmbeddable=true`; // tslint:disable-line
    //const url = `${this.base_url}search?q=${query}&maxResults=${this.max_results}&type=video&part=snippet,id&key=${YOUTUBE_API_KEY}&videoEmbeddable=true`; // tslint:disable-line
    //https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PLnRoy4r0tiuKeQd1RJ2NFnPYgPrXN8L8e&key=AIzaSyCMT0rQfgmLadfa-q6tfcUxe0mrMJcSqsg
    return this.http.get(url)
      .map(response => {
        let jsonRes = response.json();
        let res = jsonRes['items'];
       //this.lastQuery = query;
        this.nextToken = jsonRes['nextPageToken'] ? jsonRes['nextPageToken'] : undefined;

        let ids = [];

        res.forEach((item) => {
          ids.push(item.snippet.resourceId.videoId);
        });

        return this.getVideos(ids);
      })
      .toPromise()
      .catch(this.handleError)
  }

  searchNext(): Promise<any> {
    if(this.nextToken != null) {

      const url = `${this.base_url}playlistItems?part=snippet&playlistId=${this.list_id}&pageToken=${this.nextToken}&maxResults=${this.max_results}&type=video&part=snippet,id&key=${YOUTUBE_API_KEY}&videoEmbeddable=true`; // tslint:disable-line
      return this.http.get(url)
        .map(response => {
          let jsonRes = response.json();
          let res = jsonRes['items'];
          this.nextToken = jsonRes['nextPageToken'] ? jsonRes['nextPageToken'] : undefined;
          let ids = [];

          res.forEach((item) => {
            ids.push(item.snippet.resourceId.videoId);
          });

          return this.getVideos(ids);
        })
        .toPromise()
        .catch(this.handleError)

    }
    return Promise.reject();
    //.toPromise()
    //.catch(this.handleError)
      //console.log(this.nextToken);
      //const url = `${this.base_url}search?q=${this.lastQuery}&pageToken=${this.nextToken}&maxResults=${this.max_results}&type=video&part=snippet,id&key=${YOUTUBE_API_KEY}&videoEmbeddable=true`; // tslint:disable-line
  }

  getVideos(ids): Promise<any> {
    const url = `${this.base_url}videos?id=${ids.join(',')}&maxResults=${this.max_results}&type=video&part=snippet,contentDetails,statistics&key=${YOUTUBE_API_KEY}`; // tslint:disable-line

    return this.http.get(url)
      .map(results => {
        return results.json()['items'];
      })
      .toPromise()
      .catch(this.handleError)
  }

  private handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }

    this.notificationService.showNotification(errMsg);
    return Promise.reject(errMsg);
  }
}
